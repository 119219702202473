<template>
    <div @mousemove="moveLogo" style="z-index:9;position:absolute;width:100vw;">
    
        <div @click="la = true, intro = false" class="logo" :class="{ 'logo-corner': !intro}" style="z-index:10;  width: 30vh;height:100vh;margin:auto;display:flex;flex-flow:column;justify-content:center;" :style="{ transform: `rotatey(${logoX})rotatex(${logoY})translatez(60px)` }">
            <svg width="100%" height="30vw" viewBox="0 0 1500 2405" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
                                                                                                                                <g transform="matrix(1,0,0,1,-750,-297.936)">
                                                                                                                                    <g transform="matrix(0.64881,0,0,0.767763,475,-343.491)">
                                                                                                                                        <rect x="886.239" y="840.826" width="462.385" height="1271.56"/>
                                                                                                                                    </g>
                                                                                                                                    <g transform="matrix(0.561886,0.324405,-0.267539,0.463392,817.182,451.189)">
                                                                                                                                        <rect x="886.239" y="840.826" width="462.385" height="1271.56"/>
                                                                                                                                    </g>
                                                                                                                                    <g transform="matrix(0.64881,0,0,0.774256,175,1066.54)">
                                                                                                                                        <rect x="886.239" y="840.826" width="462.385" height="1271.56"/>
                                                                                                                                    </g>
                                                                                                                                    <g transform="matrix(-0.64881,0,0,0.767763,2525,-347.619)">
                                                                                                                                        <rect x="886.239" y="840.826" width="462.385" height="1271.56"/>
                                                                                                                                    </g>
                                                                                                                                    <g transform="matrix(-0.561886,0.324405,0.267539,0.463392,2182.82,447.06)">
                                                                                                                                        <rect x="886.239" y="840.826" width="462.385" height="1271.56"/>
                                                                                                                                    </g>
                                                                                                                                    <g transform="matrix(-0.64881,0,0,0.774256,2825,1062.41)">
                                                                                                                                        <rect x="886.239" y="840.826" width="462.385" height="1271.56"/>
                                                                                                                                    </g>
                                                                                                                                </g>
                                                                                                                            </svg>
            <TyperComponent style="  width: 30vh;
                                                                                                                              display: flex;
                                                                                                                              flex-flow: row;
                                                                                                                              justify-content: center;filter:invert(1);padding-left:unset!important;margin-top:2rem;" text="POINTBLANK   " search="POINTBLANK   "
                :replace="['AEROSPACE', 'ROBOTICS', 'OPENSOURCE', 'ACCELERATION', 'HARDWARE', 'INVENTION', 'CONSULTING', 'TYPE-1 CIV', 'AUTOMATION', 'PROTOTYPES', 'INTELLIGENCE']"></TyperComponent>
        </div>
    </div>
    
    
    <div class="constraint" style="z-index:1;position:absolute;">
        <a-scene v-if="la || rb || nc" loading-screen="dotsColor: black; backgroundColor: white"  vr-mode-ui="enabled: false" device-orientation-permission-ui="enabled: false" >
    
            <a-assets>
                <a-asset-item id="la-obj" src="/54mm-stroke.obj"></a-asset-item>
                <a-asset-item id="la-mtl" src="/54mm-stroke.mtl"></a-asset-item>
    
                <a-asset-item id="head-obj" src="/head-modified-final.obj"></a-asset-item>
                <a-asset-item id="head-mtl" src="/head-modified-final.mtl"></a-asset-item>
                <img id="homeThumbnail" src="city.jpg">
            </a-assets>
    
            <a-entity ref="cameraEntity" camera="userHeight: 1.8; fov: 100;" look-controls position="0 0 0" :rotation="cameraRotation">
                <a-camera look-controls-enabled="false"></a-camera>
            </a-entity>
          
            <a-entity light="type: ambient; color: #aaa"></a-entity>
            <a-entity light="type: directional; color: #fff; intensity: 0.5" position="100 100 -500"></a-entity>
            <a-entity light="type: directional; color: #fff; intensity: 0.5" position="-500 100 100"></a-entity>
    
            <a-entity animation="property: rotation; from: 0 90 0; to: 0 450 0; loop: true; dur: 10000; easing: linear;" rotation="0 0 0" scale="0.05 0.05 0.05" position="-2 0 -20">
                <a-obj-model src="#la-obj" mtl="#la-mtl" position="50 100 25"></a-obj-model>
            </a-entity>

            <a-entity position="2 0.5 -20" rotation="0 0 -90" text="font: souliosdesignfonttape-msdf.json; font-image: souliosdesignfonttape.png; align: center; width: 25; color: black; value:TOTEM LINEAR ACTUATOR"></a-entity>

            <a-entity  link="href: https://fractionnalrobots.com; " animation="property: rotation; from: 0 450 20; to: 0 90 20; loop: true; dur: 15000; easing: linear;" rotation="0 0 0"  scale="0.05 0.05 0.05" position="20 2 0">
                <a-obj-model src="#head-obj" mtl="#head-mtl" position="-25 0 25"></a-obj-model>
            </a-entity>           
             <a-entity position="20 -5 0"  rotation="0 -90 0" text="font: souliosdesignfonttape-msdf.json; font-image: souliosdesignfonttape.png;  align: center; width: 25; color: black; value:FRACTIONALROBOTS.COM"></a-entity>

             <a-entity animation="property: rotation; from: 0 90 0; to: 0 450 0; loop: true; dur: 50000; easing: linear;" rotation="0 0 0" scale="0.05 0.05 0.05" position="-2 0 20">
            <a-sphere radius="100" color="white"  src="srtm_ramp2.world.5400x2700.jpg"></a-sphere>
            </a-entity>

            <a-entity position="5 0 20" rotation="0 180 90" text="font: souliosdesignfonttape-msdf.json; font-image: souliosdesignfonttape.png; align: center; width: 25; color: black; value:NOCLIP.ORG"></a-entity>

    
        </a-scene>
    </div>
</template>

<script>
import TyperComponent from '@/components/TyperComponent.vue';

export default {
    data() {
        return {
            propValue: false,
            logoX: 0,
            logoY: 0,
            logoX2: 0,
            logoY2: 0,
            logoXr: 0,
            logoYr: 0,
            la: false,
            rb: false,
            nc: false,
            intro: true,
            cameraRotation: { x: 0, y: 0, z: 0 },
        };
    },
    methods: {
        initAudio() {
            this.audioContext = new(window.AudioContext || window.webkitAudioContext)();
            this.oscillator = this.audioContext.createOscillator();
            this.gainNode = this.audioContext.createGain();

            this.oscillator.type = 'triangle';
            this.oscillator.frequency.setValueAtTime(0, this.audioContext.currentTime);
            this.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime);

            this.oscillator.connect(this.gainNode);
            this.gainNode.connect(this.audioContext.destination);

            this.oscillator.start();
        },
        stopAudio() {
            this.oscillator.stop();
            this.oscillator.disconnect();
            this.gainNode.disconnect();
            this.audioContext.close();
        },
        rotate() {
            console.log('nextpage');

            // If 'la' is true, switch to 'rb'
            if (this.la) {
                this.la = false;
                this.rb = true;
                this.nc = false;
            }
            // If 'rb' is true, switch to 'nc'
            else if (this.rb) {
                this.la = false;
                this.rb = false;
                this.nc = true;
            }
            // If 'nc' is true, or none are true, switch to 'la'
            else {
                this.la = true;
                this.rb = false;
                this.nc = false;
            }
            this.updateCameraRotation();
        },

        applyLogoStyle() {
            const typer = document.querySelectorAll('.typer');
            const logos = document.querySelectorAll('.logo');
            logos.forEach(logo => {
                logo.style.width = '4rem';
                logo.style.margin = '2rem';
                logo.style.height = '4rem';
                logo.style.transform = 'unset';
            });
            typer.forEach(typer => {
                typer.style.display = 'none';
            });
            this.la = 1;
        },
        handleMouseMove(event) {
            const { clientX, clientY } = event;

            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const centerX = screenWidth / 2;
            const centerY = screenHeight / 2;

            const xDistance = clientX - centerX;
            const yDistance = clientY - centerY;

            const xPercentage = Math.abs(xDistance) / screenWidth;
            const yPercentage = Math.abs(yDistance) / screenHeight;

            // Generate a random variation between -1000 Hz and 1000 Hz
            const variation = (Math.random() * 2000) - 1000;

            // Calculate the base frequency based on the distance from the center
            const baseFrequency = 21000 - xPercentage * 10000;

            // Calculate the final frequency with the variation
            const frequency = baseFrequency + variation;

            // Generate a random volume between 0.1 and 0.5 based on yPercentage
            const volume = 0.1 + yPercentage * 0.4;

            // Generate a random duration between 10ms and 30ms
            const duration = Math.random() * 20 + 10;

            this.oscillator.frequency.setValueAtTime(frequency, this.audioContext.currentTime);
            this.gainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);

            // Schedule the beep to end after the specified duration
            this.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime + duration / 1000);
        },

        moveLogo(event) {
            // Get the width and height of the screen
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            // Calculate the normalized values
            const normalizedX = (event.clientX / screenWidth) * 2 - 1;
            const normalizedY = (event.clientY / screenHeight) * 2 - 1;
            // Map the normalized values to the range of -20deg to +20deg
            const mappedX = normalizedX * 20;
            const mappedY = normalizedY * -10;

            // Set the logoX and logoY values
            this.logoX = `${mappedX}deg`;
            this.logoY = `${mappedY}deg`;
            this.logoXr = `${mappedX}px`;
            this.logoYr = `${mappedY}px`;
            this.logoX2 = `${normalizedX}deg`;
            this.logoY2 = `${normalizedY * -1}deg`;
            this.logoXx = (normalizedX).toFixed(4);
            this.logoYy = (normalizedY).toFixed(4);
        },
        goToRandomWebsite() {
            const websites = [
                'https://www.eff.org/',
                'https://odriverobotics.com/',
                'https://www.fsf.org/',
                'https://www.openrightsgroup.org/',
                'https://creativecommons.org/',
                'https://privacytools.io/',
                'https://okfn.org/',
                'https://www.torproject.org/',
                'https://www.openai.com/',
                'https://archive.org/',
                'https://www.libreoffice.org/',
                'https://duckduckgo.com/',
                'https://protonmail.com/',
                'https://www.signal.org/',
                'https://foundation.mozilla.org/',
                'https://owasp.org/',
                'https://www.fossasia.org/',
                'https://www.gnu.org/',
                'https://www.apachefriends.org/',
                'https://www.openbsd.org/',
                'https://www.cryptoparty.in/',
                'https://www.oshwa.org/',
                'https://www.adafruit.com/',
                'https://www.raspberrypi.org/',
                'https://www.arduino.cc/',
                'https://www.opencores.org/',
                'https://www.open-electronics.org/',
                'https://www.ros.org/',
                'https://www.ros2.org/',
                'https://www.rosindustrial.org/',
                'https://osrfoundation.org/',
                'https://gazebosim.org/',
                'https://www.arxiv.org/',
                'https://www.biorxiv.org/',
                'https://www.plos.org/',
                'https://doaj.org/',
                'https://www.jstor.org/',
                'https://www.sci-hub.st/',
                'https://www.openstax.org/',
                'https://www.oercommons.org/',
                'https://librivox.org/',
                'https://www.gutenberg.org/',
                'https://ethereum.org/',
                'https://ethereum.foundation/',
                'https://www.bitcoin.org/',
                'https://www.cardano.org/',
                'https://www.tezos.com/',
                'https://www.audacityteam.org/',
                'https://ardour.org/',
                'https://csound.com/',
                'https://lmms.io/',
                'https://hydrogen-music.org/',
                'https://jackaudio.org/',
                'https://www.blender.org/',
                'https://www.shotcut.org/',
                'https://www.openshot.org/',
                'https://kdenlive.org/',
                'https://natrongithub.github.io/',
                'https://obsproject.com/',
                'https://www.blender.org/',
                'https://www.freecadweb.org/',
                'https://www.openscad.org/',
                'http://www.wings3d.com/',
                'https://brlcad.org/',
                'https://www.gimp.org/',
                'https://www.darktable.org/',
                'https://rawtherapee.com/',
                'https://krita.org/',
                'https://www.digikam.org/',
                'https://www.photopea.com/'
            ];



            const randomIndex = Math.floor(Math.random() * websites.length);
            const randomWebsite = websites[randomIndex];

            window.open(randomWebsite, '_blank');
        },
        playAudio() {
            const audio = new Audio('./menu.mp3');
            const audio2 = new Audio('./menu2.mp3');

            if (this.propValue === true) {
                audio.play();
            } else if (this.propValue === false) {
                audio2.play();
            }
        },
        playAudio2() {
            const audio = new Audio('./menu2.mp3');

            audio.play();
        },
        setupCameraListener() {
            const cameraEl = this.$refs.cameraEntity;

            if (cameraEl) {
                cameraEl.addEventListener('componentchanged', (event) => {
                    if (event.detail.name === 'rotation') {
                        // Update the local state or global variable
                        this.cameraRotation = cameraEl.getAttribute('rotation');

                        // If using a true global variable
                        // window.globalCameraRotation = cameraEl.getAttribute('rotation');
                    }
                });
            }
        },
    },
    mounted() { this.setupCameraListener(); },
    components: {
        TyperComponent,

    },
    computed: {

    },
};
</script>

<style lang="scss">
.logo {
    transition: all 0.1s ease;
}

.constraint {
    position: absolute;
    top: 0px;
    max-height: 100vh;
    height: 100vh;
    width: 100vw;
}

.constraint {
    h1 {
        font-size: 1rem !important;
    }
}

h1,
h2,
h3 {
    color: black !important;
    font-family: AutoWaves;
}

body {
    background: white;
}

.logo-corner {
    .typer {
        display: none !important;
    }
    position: fixed !important;
    transform: unset !important;
    height: 4rem !important;
    width: 4rem !important;
    margin: 1rem !important;
    top: 0px !important;
    left: 0px !important;
}
</style>