<template>
	<main class="login">
	
	
		<section class="forms">
			<form v-if="!alreadyReg" class="register" @submit.prevent="register">
				<h1 style="color:white;text-align:center;">Register</h1>
				<p style="cursor:pointer;margin:1rem 0rem;" v-on:click="alreadyReg = !alreadyReg">Already Registered? <b>Click Here to Login</b></p>
	
				<div class="editor-field editor-field__textbox">
					<div class="editor-field__label-container">
						<label class="editor-field__label">Email</label>
					</div>
	
					<div class="editor-field__container">
						<input type="text" class="editor-field__input" v-model="register_form.email" />
					</div>
					<span class="editor-field__bottom"></span>
					<div class="editor-field__noise"></div>
				</div>
				<div class="editor-field editor-field__textbox">
					<div class="editor-field__label-container">
						<label class="editor-field__label">Password</label>
					</div>
	
					<div class="editor-field__container">
						<input type="password" class="editor-field__input" v-model="register_form.password" />
					</div>
					<span class="editor-field__bottom"></span>
					<div class="editor-field__noise"></div>
				</div>
				<div class="btn btn--primary">
					<div class="btn__container"></div><input type="submit" value="Register" />
	
					<div class="btn__bottom"></div>
					<div class="btn__noise"></div>
				</div>
				<p style="width:300px;max-width:100%;"> <br>Want Anon Robot Access? Use <a style="text-decoration:none;color:#FFD400;text-shadow:1px 1x black;" target="_blank" href="https://account.proton.me/signup?plan=free&billing=12&minimumCycle=12&currency=USD&product=mail&language=en"><b>Proton Mail</b></a></p>
	
			</form>
			<form v-if="alreadyReg" class="login" @submit.prevent="login">
				<h1 style="color:white;text-align:center;">Login</h1>
				<p style="cursor:pointer;margin:1rem 0rem;" v-on:click="alreadyReg = !alreadyReg">Need An Account? <b>Click Here to Register</b></p>
	
				<div class="editor-field editor-field__textbox">
					<div class="editor-field__label-container">
						<label class="editor-field__label">Email</label>
					</div>
	
					<div class="editor-field__container">
						<input type="text" class="editor-field__input" v-model="login_form.email" />
					</div>
					<span class="editor-field__bottom"></span>
					<div class="editor-field__noise"></div>
				</div>
				<div class="editor-field editor-field__textbox">
					<div class="editor-field__label-container">
						<label class="editor-field__label">Password</label>
					</div>
	
					<div class="editor-field__container">
						<input type="password" class="editor-field__input" v-model="login_form.password" />
					</div>
					<span class="editor-field__bottom"></span>
					<div class="editor-field__noise"></div>
				</div>
				<div class="btn btn--primary">
					<div class="btn__container"></div><input type="submit" value="Login">
					<div class="btn__bottom"></div>
					<div class="btn__noise"></div>
				</div>
				<p style="width:300px;max-width:100%;"> <br>Want Anon Robot Access? Use <a style="text-decoration:none;color:#FFD400;text-shadow:1px 1x black;" target="_blank" href="https://account.proton.me/signup?plan=free&billing=12&minimumCycle=12&currency=USD&product=mail&language=en"><b>Proton Mail</b></a></p>
	
			</form>
	
			<!--
																															<form>
																																<h1>FRACTIONAL ROBOTS</h1>
																																<h2>Register</h2>
																																<input type="email" placeholder="Email address" v-model="register_form.email" />
																																<input type="password" placeholder="Password" v-model="register_form.password" />
																																<input type="submit" value="Register" />
																															</form>
																													
																															<form>
																																<h1>FRACTIONAL ROBOTS</h1>
																													
																																<h2>Login</h2>
																																<input type="email" placeholder="Email address" v-model="login_form.email" />
																																<input type="password" placeholder="Password" v-model="login_form.password" />
																																<input type="submit" value="Login" />
																															</form>
																													-->
		</section>
	</main>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
	setup() {
		const login_form = ref({});
		const register_form = ref({});
		const store = useStore();

		const login = () => {
			store.dispatch('login', login_form.value);
		}

		const register = () => {
			store.dispatch('register', register_form.value);
		}

		return {
			login_form,
			register_form,
			login,
			register
		}
	},
	data() {
		return {
			alreadyReg: false,
		}
	},
	methods() {},
	mounted() {

	}

}
</script>

<style lang="scss" scoped>
.forms {
	position: relative;
	z-index: 10;
	margin: auto;
	display: flex;
	flex-flow: column;
	justify-content: center;
	min-height: 100vh;
	max-width: 300px;
}

form {
	display: flex;
	flex-flow: column;
	justify-content: center;
}

form.register {}

h2 {
	font-size: 2rem;
	text-transform: uppercase;
	margin-bottom: 2rem;
}

@media (max-width: 1024px) {
	.forms {
		flex-flow: wrap;
	}
}

$black: #0F1020;
$blackLight: #151830;
//grays
$gray-900: #5E5F84;
$gray-600: #70719C;
$gray-300: #7F83A2;
//white
$white: #B3B5D2;
$blue: #383CAB;
$green: #249163;
$red: #BF2E50;
$yellow: #ffD300;
//sizing
$space-size: 8px;
$space-size-2: $space-size * 2;
$space-size-3: $space-size * 3;
$space-size-4: $space-size * 4;
$space-size-5: $space-size * 5;
$space-size-6: $space-size * 6;
$space-size-7: $space-size * 7;
$space-size-8: $space-size * 8;
* {
	box-sizing: border-box;
}

html,
body,
body {
	display: flex;
	align-items: center;
	justify-content: center;
}

h1 {font-size:2rem;
}

.container {
	right: 1rem;
	width: 300px;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 70px;
}

.editor-field {
	width: 300px;
	height: 64px; //$space-size-6;
	position: relative;
	margin: $space-size-2 - 2 0;
	.noise__el {
		fill: $gray-600;
	}
	&__noise {
		position: absolute;
		bottom: -2px;
		width: 100%;
		height: calc(100% + 2px);
		z-index: 2;
		pointer-events: none;
	}
	&__container {
		clip-path: polygon( 0% 0%, calc(100% - 8px) 0, 100% 8px, 100% 100%, 95% 100%, calc(0% + 8px) 100%, 0% calc(100% - 8px), 0% calc(100% + 8px));
		border: 2px solid $gray-900;
		width: 100%;
		height: $space-size-6;
		position: absolute;
		bottom: 2px;
		&:before,
		&:after {
			content: "";
			height: 2px;
			width: 11.5px;
			background: $gray-900;
			display: block;
			position: absolute;
			z-index: 1;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			border-radius: 5px;
		}
		&:before {
			right: -3.1px;
			top: 1.6px;
		}
		&:after {
			left: -3.1px;
			bottom: 1.6px;
		}
	}
	&__bottom {
		position: absolute;
		content: "";
		display: block;
		height: $space-size / 4;
		width: 30%;
		background: $gray-900;
		right: 0px;
		clip-path: polygon(0 0, 100% 0%, 100% 100%, calc(0% + 2px) 100%);
		bottom: 0px;
	}
	&__label-container {
		position: absolute;
		top: 0px;
		&:after {
			position: absolute;
			content: "";
			display: block;
			height: 2px;
			width: 32px;
			background: $gray-900;
			right: -29px;
			-webkit-clip-path: polygon(0 0, calc(100% - 2px) 0%, 100% 100%, 22% 100%);
			clip-path: polygon(0 0, calc(100% - 2px) 0%, 100% 100%, 0% 100%);
			bottom: 2px;
		}
	}
	&__label {
		position: relative;
		display: block;
		height: $space-size-2;
		width: auto;
		background: $gray-900;
		left: 0px;
		color: $black;
		font-size: 9px;
		text-transform: uppercase;
		letter-spacing: 1.25px;
		font-weight: 600;
		padding: 0 $space-size-3 0 $space-size-2;
		display: flex;
		align-items: center;
		clip-path: polygon(0 0, calc(100% - 10px) 0, 100% 100%, 0% 100%);
	}
	&__input {
		width: 100%;
		height: 100%;
		border: none;
		outline: none;
		background: $black;
		color: $white;
		padding: $space-size $space-size-2;
		letter-spacing: 0.2px;
		&:focus {
			animation: 0.05s infinite alternate blink;
			@keyframes blink {
				from {
					background: $black;
				}
				to {
					background: lighten($black, 1%);
				}
			}
		}
	}
	$parent: &;
	&:hover,
	&.is-focused {
		#{$parent}__bottom {
			background: $gray-600;
		}
		#{$parent}__container {
			border-color: $gray-600;
			&:after,
			&:before {
				background: $gray-600 !important;
			}
		}
		#{$parent}__label-container {
			&:after {
				background: $gray-600;
			}
		}
		#{$parent}__label,
		#{$parent}__helper {
			background: $gray-600;
		}
	}
}

.btn {
	display: inline-block;
	position: relative;
	height: $space-size-7 - 6;
	margin-top: $space-size-4;
	cursor: pointer;
	&__noise {
		position: absolute;
		top: -28%;
		height: 66px;
		width: 100%;
		pointer-events: none;
	}
	&--primary {
		input {
			font-family: Mechsuit;
			font-size: 1rem;
			line-height: 1;
			padding: 1rem;
			background: unset;
			border: unset;
			position: relative;
			top: -3.1rem;
			z-index: 5;
			color: black;
		}
		.btn__container {
			font-family: Mechsuit;
			background: $yellow;
			border-color: lighten($yellow, 10%);
			display: flex;
			align-items: center;
			line-height: 10px;
			pointer-events: none;
			color: lighten($yellow, 47%);
		}
		.noise__el {
			fill: lighten($yellow, 10%);
		}
		&:hover {
			.btn__container {
				background: darken($yellow, 3%);
				border-color: lighten($yellow, 8%);
			}
			.btn__bottom {
				background: darken($yellow, 3%);
				border-color: lighten($yellow, 8%);
			}
		}
		&:active {
			.btn__container {
				background: darken($yellow, 8%);
				border-color: lighten($yellow, 2%);
				color: lighten($yellow, 40%);
			}
			.btn__bottom {
				background: darken($yellow, 8%);
				border-color: lighten($yellow, 2%);
			}
		}
	}
	&:before,
	&:after {
		content: "";
		height: 2px;
		width: 11px;
		background: lighten($yellow, 10%);
		display: block;
		position: absolute;
		z-index: 1;
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		border-radius: 2px;
		border-radius: 2px;
	}
	&:before {
		right: -1.07px;
		top: 4px;
	}
	&:after {
		left: -1.07px;
		bottom: 6px;
	}
	&__bottom {
		position: absolute;
		content: "";
		display: block;
		height: $space-size / 2;
		width: 50%;
		background: $yellow;
		pointer-events: none;
		right: 0px;
		bottom: 0px;
		clip-path: polygon(0 0, 100% 0%, 100% 100%, calc(0% + 4px) 100%);
		border-bottom: 2px solid lighten($yellow, 10%);
		border-right: 2px solid lighten($yellow, 10%);
		&:before {
			content: "";
			height: 2px;
			width: 11px;
			background: lighten($yellow, 10%);
			display: block;
			position: absolute;
			left: -5px;
			bottom: 2px;
			z-index: 1;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	&__container {
		height: 100%;
		background: none;
		border: none;
		color: white;
		padding: 0 $space-size-3;
		height: $space-size-6;
		border: 2px solid;
		clip-path: polygon( 0% 0%, calc(100% - 8px) 0, 100% 8px, 100% 100%, 95% 100%, calc(0% + 8px) 100%, 0% calc(100% - 8px), 0% 20%);
	}
}
</style>