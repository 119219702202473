import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
  apiKey: "AIzaSyAN_WV-xDIZl6Wd2YtsF1AxdKadSrWlrH0",
  authDomain: "fractionalrobots.firebaseapp.com",
  projectId: "fractionalrobots",
  storageBucket: "fractionalrobots.appspot.com",
  messagingSenderId: "18641236401",
  appId: "1:18641236401:web:2bc3a2b26b2b743cfa8d90",
  measurementId: "G-X935D2HGR1"
};


const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

export { auth }