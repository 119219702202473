 
<script>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import slider from "vue3-slider"
export default {
    setup() {
        const store = useStore()

        onBeforeMount(() => {
            store.dispatch('fetchUser')
        })
    },

    data() {
        return {
            diffHeight: window.outerHeight - window.innerHeight,
            mobileHeight: window.outerHeight,
            model: 1,
            sliderVal: 1,
            sliderValY: 10,
            sliderValX: 10,
            sliderValZ: 10,
            sliderValZoom: 400,
            sliderValPan: 250,
            sliderValMinY: 0,
            sliderValMaxY: 360,
            sliderValMinX: 0,
            sliderValMaxX: 360,
            sliderValMinZ: 0,
            sliderValMaxZ: 360,
            sliderValMinZoom: 0,
            sliderValMaxZoom: 800,
            sliderValMinPan: 0,
            sliderValMaxPan: 500,
        }
    },
    methods: {
        updateAngleY() {
            const element = document.getElementById("object");
            element.object3D.rotation.y = this.sliderValY / 57.29578;
        },

        updateAngleX() {
            const element = document.getElementById("object");
            element.object3D.rotation.x = this.sliderValX / 57.29578;
        },

        updateAngleZ() {
            const element = document.getElementById("object");
            element.object3D.rotation.z = this.sliderValZ / 57.29578;
        },

        updateAnglePan() {
            const element = document.getElementById("object");
            element.object3D.position.x = this.sliderValPan - 250;
        },

        updateAngleZoom() {
            const element = document.getElementById("object");
            element.object3D.position.z = this.sliderValZoom - 800;
        },
        resetCam() {
            const element = document.getElementById("cam");
            element.components['orbit-controls'].controls.reset();
        },
    },
    components: {
        "vue3-slider": slider
    }
}
</script>

 
<template>
    <div class="header-data">
        <div v-if="sec1">
        </div>
        <div v-if="sec2">
        </div>
        <div v-if="sec3">
        </div>
        <div v-if="sec4">
        </div>
        <div v-if="sec5">
        </div>
        <div v-if="sec6">
        </div>
    </div>
    
    
    <a-scene shadow="type: pcfsoft" renderer="antialias: true;  sortObjects: true; physicallyCorrectLights: true;" stats fog="type: exponential; color: #000; far: 1000;" loading-screen="enabled: false;" alpha="true" embedded vr-mode-ui="enabled: false">
        <a-assets>
            <a-asset-item id="head-obj" src="/head-modified-final.obj"></a-asset-item>
            <a-asset-item id="head-mtl" src="/head-modified-final.mtl"></a-asset-item>
            <a-asset-item id="neck-obj" src="/head-full-stewart-dynamics-neckonly.obj"></a-asset-item>
            <a-asset-item id="neck-mtl" src="/head-full-stewart-dynamics-neckonly.mtl"></a-asset-item>
            <a-asset-item id="torso-obj" src="/full-torso.obj"></a-asset-item>
            <a-asset-item id="torso-mtl" src="/full-torso.mtl"></a-asset-item>
            <a-asset-item id="full-hips-obj" src="/full-hips.obj"></a-asset-item>
            <a-asset-item id="full-hips-mtl" src="/full-hips.mtl"></a-asset-item>
            <a-asset-item id="rightleg-obj" src="/rightleg.obj"></a-asset-item>
            <a-asset-item id="leftleg-obj" src="/leftleg.obj"></a-asset-item>
            <a-asset-item id="leftleg-mtl" src="/leftleg.mtl"></a-asset-item>
            <a-asset-item id="rightleg-mtl" src="/rightleg.mtl"></a-asset-item>
            <img id="ground" src="https://www.transparenttextures.com/patterns/concrete-wall.png">
        </a-assets>
    
    
    
        <a-entity light="type: ambient; color: #aaa"></a-entity>
        <a-entity light="type: ambient; color: #fff; intensity: 0.5"></a-entity>
        <a-entity light="type: directional; color: #ff2; intensity: 0.5" position="200 200 -200"></a-entity>
        <a-entity light="type: directional; color: #fa2; intensity: 0.6" position="-200 200 200"></a-entity>
        <a-entity light="type: hemisphere; color: #fa2; intensity: 0.2" position="-200 200 -200"></a-entity>
        <a-entity light="type: hemisphere; color: #ffa; intensity: 0.6" position="200 200 200"></a-entity>
        <a-entity light="type: point; color: #ff2; intensity: 0.5" position="0 200 0"></a-entity>
        <a-entity light="type: hemisphere; color: #fff; intensity: 0.7" position="0 -200 -200"></a-entity>
        <a-entity light="type: point; color: #fff; intensity: 0.5" position="-200 -200 0"></a-entity>
    
    
        <a-entity id="object" v-if="model == 1" rotation="0 0 0" position="0 0 0">
            <a-obj-model src="#head-obj" mtl="#head-mtl" position="-25 0 25"></a-obj-model>
        </a-entity>
    
        <a-entity id="object" v-if="model == 3" rotation="0 0 0" position="0 0 0">
            <a-obj-model src="#torso-obj" mtl="#torso-mtl" position="-20 0 -80"></a-obj-model>
        </a-entity>
    
        <a-entity id="object" v-if="model == 2" rotation="0 0 0" position=" 0 100 0">
            <a-obj-model src="#neck-obj" mtl="#neck-mtl" position="-290 0 -30"></a-obj-model>
        </a-entity>
    
        <a-entity id="object" v-if="model == 4" rotation="0 0 0" position="0 0 0">
            <a-obj-model src="#full-hips-obj" mtl="#full-hips-mtl" position="10 0 -30"></a-obj-model>
        </a-entity>
    
        <a-entity id="object" v-if="model == 5" rotation="0 0 0" position="0 -700 0">
            <a-obj-model src="#leftleg-obj" mtl="#leftleg-mtl" position="-20 0 80"></a-obj-model>
        </a-entity>
    
        <a-entity id="object" v-if="model == 6" rotation="0 0 0" position="0 -700 0">
            <a-obj-model src="#rightleg-obj" mtl="#rightleg-mtl" position="-20 0 -80"></a-obj-model>
        </a-entity>
    
        <a-entity id="cam" camera look-controls orbit-controls="target: 0 1.6 0; minDistance: 0.5; maxDistance: 800; initialPosition: 0 200 200"></a-entity>
    </a-scene>
    
    <div class="showitems">
        <div class="sec1" v-on:click="model = 1">
            <p>HEAD</p>
        </div>
        <div class="sec2" v-on:click="model = 2">
            <p>NECK</p>
        </div>
        <div class="sec3" v-on:click="model = 3">
            <p>TORSO</p>
        </div>
        <div class="sec4" v-on:click="model = 4">
            <p>HIPS</p>
        </div>
        <div class="sec5" v-on:click="model = 5">
            <p>LLEG</p>
        </div>
        <div class="sec6" v-on:click="model = 6">
            <p>RLEG</p>
        </div>
        <div class="sec6" v-on:mouseup="resetCam()">
            <p>RESET</p>
        </div>
    </div>
    
    <div class="flex-items">
    
    
        <div class="left-slider">
            <vue3-slider v-on:pointermove="updateAngleX()" :min="sliderValMinX" :max="sliderValMaxX" v-model="sliderValX" height="16" style="box-shadow:5px 5px 20px rgba(0,0,0,0.2),-5px -5px 20px rgba(255,255,255,0.2);border-radius:4rem;" color="#FFD300" orientation="vertical"
                track-color="#11111122" />
        </div>
        <div class="center-slider">
            <vue3-slider v-on:pointermove="updateAngleY()" :min="sliderValMinY" :max="sliderValMaxY" v-model="sliderValY" height="16" style="box-shadow:5px 5px 20px rgba(0,0,0,0.2),-5px -5px 20px rgba(255,255,255,0.2);border-radius:40rem;z-index:10;" color="#FFD300"
                orientation="circular" track-color="#11111122" />
        </div>
        <div class="right-slider">
            <vue3-slider v-on:pointermove="updateAngleZ()" :min="sliderValMinZ" :max="sliderValMaxZ" v-model="sliderValZ" height="16" style="box-shadow:5px 5px 20px rgba(0,0,0,0.2),-5px -5px 20px rgba(255,255,255,0.2);border-radius:4rem;z-index:10;" color="#FFD300"
                orientation="vertical" track-color="#11111122" />
        </div>
        <div class="top-slider">
            <vue3-slider v-on:pointermove="updateAnglePan()" :min="sliderValMinPan" :max="sliderValMaxPan" v-model="sliderValPan" height="16" style="box-shadow:5px 5px 20px rgba(0,0,0,0.2),-5px -5px 20px rgba(255,255,255,0.2);border-radius:4rem;z-index:10;" color="#FFD300"
                orientation="horizontal" track-color="#11111122" />
        </div>
        <div class="bottom-slider">
            <vue3-slider v-on:pointermove="updateAngleZoom()" :min="sliderValMinZoom" :max="sliderValMaxZoom" v-model="sliderValZoom" height="16" style="box-shadow:5px 5px 20px rgba(0,0,0,0.2),-5px -5px 20px rgba(255,255,255,0.2);border-radius:4rem;z-index:10;" color="#FFD300"
                orientation="horizontal" track-color="#11111122" />
        </div>
    </div>
</template>

<style lang="scss">
@media (min-width: 1024px) {
    .rs-base {
        transform-origin: bottom right;
        transition: all 0.5s ease;
        color: white !important;
        right: 20px !important;
        top: 20px !important;
        position: fixed !important;
        left: unset !important;
        font-family: 'Rajdhani', sans-serif;
        background-color: transparent !important;
        overflow: visible !important;
        &:after {
            content: '';
            width: 50%;
            height: 2rem;
            position: absolute;
            bottom: -1rem;
            left: -1rem;
            border-left: 2px solid #ffd300;
            border-bottom: 2px solid #ffd300;
            background-image: linear-gradient(-135deg, transparent, transparent calc(100% - 0.5rem), #ffd300 calc(100% - 0.5rem), #ffd300 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0.7rem 100%, 0 calc(100% - 0.7rem));
        }
        &:before {
            content: '';
            width: 50%;
            height: 2rem;
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
            border-right: 2px solid #ffd300;
            clip-path: polygon(0 0, calc(100% - 0.7rem) 0, 100% 0.7rem, 100% 100%, 0 100%);
            background-image: linear-gradient(45deg, transparent, transparent calc(100% - 0.5rem), #ffd300 calc(100% - 0.5rem), #ffd300 100%);
            border-top: 2px solid #ffd300;
        }
        &:hover {}
        h1 {
            text-align: right;
            color: #ffd300 !important;
            font-size: 0.5rem !important;
        }
    }
    .rs-canvas {
        clip-path: polygon(0 0, calc(100% - 0.25rem) 0, 100% 0.25rem, 100% 100%, 0.25rem 100%, 0 calc(100% - 0.25rem));
        filter: hue-rotate(66deg) brightness(4.5);
    }
    .rs-counter-base.alarm {
        color: #ffd300 !important;
        font-weight: 900 !important;
        text-shadow: unset !important;
        font-family: 'Rajdhani', sans-serif;
        box-shadow: unset;
    }
    .rs-counter-value {
        padding-right: 0.5rem !important;
        font-weight: 500 !important;
        text-shadow: unset !important;
        font-family: 'Rajdhani', sans-serif;
        box-shadow: unset;
    }
    .rs-counter-id {
        font-weight: 500 !important;
    }
}

@media (max-width: 1024px) {
    .rs-base {
        transform-origin: bottom right;
        transform: scale(0.5);
        transition: all 0.5s ease;
        color: white !important;
        right: 0.75rem !important;
        top: unset !important;
        bottom: 2.5rem !important;
        position: fixed !important;
        left: unset !important;
        font-family: 'Rajdhani', sans-serif;
        background-color: transparent !important;
        &:hover {}
        h1 {
            text-align: right;
            color: #ffd300 !important;
            font-size: 0.5rem !important;
        }
    }
    .rs-canvas {
        clip-path: polygon(0 0, calc(100% - 0.25rem) 0, 100% 0.25rem, 100% 100%, 0.25rem 100%, 0 calc(100% - 0.25rem));
        filter: hue-rotate(66deg) brightness(4.5);
    }
    .rs-counter-base.alarm {
        color: #ffd300 !important;
        font-weight: 500 !important;
        text-shadow: unset !important;
        font-family: 'Rajdhani', sans-serif;
        box-shadow: unset;
    }
    .rs-counter-value {
        padding-right: 0.5rem !important;
        font-weight: 500 !important;
        text-shadow: unset !important;
        font-family: 'Rajdhani', sans-serif;
        box-shadow: unset;
    }
    .rs-counter-id {
        font-weight: 500 !important;
    }
}

.flex-items {
    border-radius: 100rem;
    display: none;
    flex-flow: wrap;
    position: fixed;
    right: calc(50vw - 300px - 1rem);
    top: calc(50vh - 300px - 1rem);
    height: calc(600px + 2rem);
    width: calc(600px + 2rem);
    justify-content: center;
    overflow: visible;
    .center-slider {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0px;
    }
    .left-slider {
        position: absolute;
        left: -5rem;
        height: 100%;
        width: 2rem;
        z-index: 20;
    }
    .right-slider {
        position: absolute;
        right: -5rem;
        height: 100%;
        width: 2rem;
        z-index: 20;
    }
    .top-slider {
        position: absolute;
        top: -5rem;
        width: 100%;
        height: 2rem;
        z-index: 20;
    }
    .bottom-slider {
        position: absolute;
        bottom: -5rem;
        width: 100%;
        height: 2rem;
        z-index: 20;
    }
    &:hover {
        &::after {
            content: '';
        }
        &::before {
            content: '';
        }
    }
    &::after {
        width: 100%;
        border-radius: 100%;
        height: 100%;
        position: absolute;
        content: '';
    }
    &::before {
        content: '';
    }
}

.aframescene {
    position: relative;
    width: 300px;
    height: 300px;
    z-index: 5;
    margin: 1rem;
    border-radius: 100%;
    &::before {
        transform-origin: 50% 50%;
        transition: all 0.5s ease;
        transform: rotate(45deg);
        content: '';
        top: -20%;
        width: 1px;
        background: #fff5;
        right: 50%;
        min-height: 140%;
        min-width: 1px;
        z-index: 0;
        display: flex;
        position: absolute;
    }
    &::after {
        transform-origin: 50% 50%;
        transition: all 0.5s ease;
        transform: rotate(-45deg);
        content: '';
        top: -20%;
        width: 1px;
        background: #fff5;
        right: 50%;
        min-height: 140%;
        min-width: 1px;
        z-index: 0;
        display: flex;
        position: absolute;
    }
}

.corner-tl {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 1rem;
    height: 1rem;
    border-top: 1px solid white;
    border-left: 1px solid white;
}

.corner-tr {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 1rem;
    height: 1rem;
    border-top: 1px solid white;
    border-right: 1px solid white;
}

.corner-bl {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 1rem;
    height: 1rem;
    border-bottom: 1px solid white;
    border-left: 1px solid white;
}

.corner-br {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 1rem;
    height: 1rem;
    border-bottom: 1px solid white;
    border-right: 1px solid white;
}

a-scene {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: fixed;
}

.showitems {
    z-index: 100;
    position: fixed;
    transform-origin: top left;
    bottom: 0rem;
    top: unset;
    height: 3rem;
    width: 700px;
    right: 1.5rem;
    justify-content: flex-end;
    display: flex;
    flex-flow: row;
    &:after {
        content: '';
        position: absolute;
        left: 1rem;
        top: 0rem;
        height: 2rem;
        width: 1px;
        border-top: 1px solid #ffd300;
        border-bottom: 1px solid #ffd300;
    }
    &:before {
        content: '';
        position: absolute;
        right: -1rem;
        top: 0rem;
        height: 2rem;
        width: 1px;
        border-top: 1px solid #ffd300;
        border-bottom: 1px solid #ffd300;
    }
    div {
        height: 2.25rem;
        padding: 0.5rem 0.5rem;
        &:hover {
            p {
                background-color: #ffd300;
            }
        }
        p {
            clip-path: polygon(0 0, calc(100% - 0.25rem) 0, 100% 0.25rem, 100% 100%, 0.25rem 100%, 0 calc(100% - 0.25rem));
            background-color: white;
            color: black;
            min-width: 80px;
            width: 100%;
            font-weight: bold;
            padding: 0rem 0.5rem;
        }
    }
}

@media (max-width: 1024px) {
    .showitems {
        z-index: 100;
        position: fixed;
        bottom: 0.5rem;
        height: 2rem;
        top: unset;
        width: calc(100vw - 1rem);
        left: 0.5rem;
        justify-content: space-around;
        display: flex;
        flex-flow: row;
        right: 2rem;
        padding: 0rem;
        right: 0rem;
        transform: rotate(0deg);
        &:after {
            content: '';
            position: absolute;
            left: 0rem;
            top: 0rem;
            height: 2rem;
            width: 1px;
            border-top: 1px solid #ffd300;
            border-bottom: 1px solid #ffd300;
        }
        &:before {
            content: '';
            position: absolute;
            right: 0rem;
            top: 0rem;
            height: 2rem;
            width: 1px;
            border-top: 1px solid #ffd300;
            border-bottom: 1px solid #ffd300;
        }
        div {
            &:hover {
                p {
                    background-color: white;
                }
            }
            p {
                font-size: 0.75rem;
                background: #ffd300;
                color: black;
                width: 100%;
                font-weight: bold;
                min-width: unset;
                padding: 0rem 0.5rem;
            }
        }
    }
    .flex-items {
        padding-top: 1rem;
        padding-bottom: 0rem;
        top: unset;
        bottom: 5.5rem;
        width: calc(100vw - 12rem);
        height: calc(100vw - 12rem);
        right: 5.5rem;
        z-index: 100;
        &:hover {
            &::after {
                content: '';
            }
            &::before {
                content: '';
            }
        }
        &::before {
            content: '';
        }
    }
}
</style>