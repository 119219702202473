<template>
    <div class="perspective" @mousemove=" handleMouseMove2">
        <router-view />
        <div :style="componentStyle + { transform: `rotatey(${logoX})rotatex(${logoY})`}"></div>
    </div>
</template>

<script>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'

export default {
    setup() {
        const store = useStore()

        onBeforeMount(() => {
            store.dispatch('fetchUser')
        })
    },
    data() {
        return {
            componentStyle: {
                top: '50%',
                left: '50%',
            },
            dateTime: null,
            menu: false,
            logoX: 0,
            logoY: 0,
            audioContext: null,
            oscillator: null,
            gainNode: null,
        }
    },
    mounted() {
        if (window.DeviceOrientationEvent) {
            window.addEventListener('deviceorientation', this.handleDeviceOrientation);
        }
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
        this.timer = setInterval(() => {
            this.dateTime = this.currentDateTime();
        }, 1000);
        this.timer = setInterval(() => {
            this.reqDateTime = this.requestedDateTime();
        }, 1000);
        this.initAudio();

    },
    beforeUnmount() {
        if (window.DeviceOrientationEvent) {
            window.removeEventListener('deviceorientation', this.handleDeviceOrientation);
        }
        this.stopAudio();
    },
    methods: {
        handleMouseMove2(event) {
            // Call the first function
            this.handleMouseMove(event);

            // Call the second function
            this.moveLogo(event);
        },
        handleDeviceOrientation(event) {
            const { gamma, beta } = event;

            // Calculate the new position based on gamma and beta values
            const newPosition = this.calculateNewPosition(gamma, beta);

            // Update the component style
            this.componentStyle.top = newPosition.top;
            this.componentStyle.left = newPosition.left;
        },
        calculateNewPosition(gamma, beta) {
            // Adjust the position calculation based on your requirements
            const maxOffset = 50; // Maximum offset in pixels
            const offsetX = gamma / 90 * maxOffset;
            const offsetY = beta / 90 * maxOffset;

            const centerX = window.innerWidth / 2;
            const centerY = window.innerHeight / 2;

            const newTop = centerY + offsetY;
            const newLeft = centerX + offsetX;

            return {
                top: `${newTop}px`,
                left: `${newLeft}px`,
            };
        },
        moveLogo(event) {
            // Get the width and height of the screen
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            // Calculate the normalized values
            const normalizedX = (event.clientX / screenWidth) * 2 - 1;
            const normalizedY = (event.clientY / screenHeight) * 2 - 1;

            // Map the normalized values to the range of -20deg to +20deg
            const mappedX = normalizedX * 2;
            const mappedY = normalizedY * -2;

            // Set the logoX and logoY values
            this.logoX = `${mappedX}deg`;
            this.logoY = `${mappedY}deg`;
            this.logoX2 = `${normalizedX}deg`;
            this.logoY2 = `${normalizedY * -1}deg`;
            this.logoXx = (normalizedX).toFixed(4);
            this.logoYy = (normalizedY).toFixed(4);
        },

        currentDateTime() {
            const current = new Date();
            const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            const time = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
            const dateTime = date + ' ' + time;
            return dateTime;
        },
        requestedDateTime() {
            const current = new Date();
            const date = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
            const time = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
            const reqDateTime = date + ' ' + time;
            return reqDateTime;
        },
        initAudio() {
            this.audioContext = new(window.AudioContext || window.webkitAudioContext)();
            this.oscillator = this.audioContext.createOscillator();
            this.gainNode = this.audioContext.createGain();

            this.oscillator.type = 'triangle';
            this.oscillator.frequency.setValueAtTime(0, this.audioContext.currentTime);
            this.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime);

            this.oscillator.connect(this.gainNode);
            this.gainNode.connect(this.audioContext.destination);

            this.oscillator.start();
        },
        stopAudio() {
            this.oscillator.stop();
            this.oscillator.disconnect();
            this.gainNode.disconnect();
            this.audioContext.close();
        },

        handleMouseMove(event) {
            const { clientX, clientY } = event;

            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const centerX = screenWidth / 2;
            const centerY = screenHeight / 2;

            const xDistance = clientX - centerX;
            const yDistance = clientY - centerY;

            const xPercentage = Math.abs(xDistance) / screenWidth;
            const yPercentage = Math.abs(yDistance) / screenHeight;

            // Generate a random variation between -1000 Hz and 1000 Hz
            const variation = (Math.random() * 2000) - 1000;

            // Calculate the base frequency based on the distance from the center
            const baseFrequency = 21000 - xPercentage * 10000;

            // Calculate the final frequency with the variation
            const frequency = baseFrequency + variation;

            // Generate a random volume between 0.1 and 0.5 based on yPercentage
            const volume = 0.1 + yPercentage * 0.4;

            // Generate a random duration between 10ms and 30ms
            const duration = Math.random() * 20 + 10;

            this.oscillator.frequency.setValueAtTime(frequency, this.audioContext.currentTime);
            this.gainNode.gain.setValueAtTime(volume, this.audioContext.currentTime);

            // Schedule the beep to end after the specified duration
            this.gainNode.gain.setValueAtTime(0, this.audioContext.currentTime + duration / 1000);
        },

    }

}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@font-face {
    font-family: Mechsuit;
    src: url(./assets/fonts/Mechsuit.otf);
}

@font-face {
    font-family: AutoWaves;
    src: url(./assets/fonts/souliosdesignfonttape.otf);
}

.perspective {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    display: block;
    perspective: 600px;
    transition: all 0.5s ease;
}

body {
    margin: unset;
    overflow: hidden;
}

.bgimg {
    transition: all 0.5s ease;
    position: fixed;
    z-index: -1;
    left: -100px;
    top: -100px;
    height: calc(100vh + 200px);
    width: calc(100vw + 200px);
    background-image: url(/02c7704486c644de8673e0bc5f07d4b1.jpeg);
    background-size: cover;
}

#app {
    perspective: 600px;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    background-size: cover;
}

.tg {
    clip-path: polygon(100% 0, 100% calc(100% - 0.7rem), calc(100% - 0.7rem) 100%, 0 100%, 0 0);
    background: #ffd300;
    padding: 1rem;
    width: 5rem;
    position: absolute;
    margin: 0rem;
    left: 10rem;
}

.grid {
    display: none;
    position: fixed;
    top: 0px;
    opacity: 1;
    height: 100vh;
    pointer-events: none;
    z-index: 4;
    width: 100vw;
    left: 0px;
    background: repeating-linear-gradient(180deg, transparent, transparent calc(8.33333vw/2 - 1px), #fff5 calc(8.33333vw/2 - 1px), #fff5 calc(8.33333vw/2));
}

.vertical-raster-lines {
    position: absolute;
    height: 100%;
    top: 0;
    mix-blend-mode: difference;
    background-color: #fff5;
    width: 1px;
}

.line-1 {
    left: 4.166665%;
}

.line-2 {
    left: calc(4.166665%*2);
}

.line-3 {
    left: calc(4.166665%*3);
}

.line-4 {
    left: calc(4.166665%*4);
}

.line-5 {
    left: calc(4.166665%*5);
}

.line-6 {
    left: calc(4.166665%*6);
}

.line-7 {
    left: calc(4.166665%*7);
}

.line-8 {
    left: calc(4.166665%*8);
}

.line-9 {
    left: calc(4.166665%*9);
}

.line-10 {
    left: calc(4.166665%*10);
}

.line-11 {
    left: calc(4.166665%*11);
}

.line-12 {
    left: calc(4.166665%*12);
}

.line-13 {
    left: calc(4.166665%*13);
}

.line-14 {
    left: calc(4.166665%*14);
}

.line-15 {
    left: calc(4.166665%*15);
}

.line-16 {
    left: calc(4.166665%*16);
}

.line-17 {
    left: calc(4.166665%*17);
}

.line-18 {
    left: calc(4.166665%*18);
}

.line-19 {
    left: calc(4.166665%*19);
}

.line-20 {
    left: calc(4.166665%*20);
}

.line-21 {
    left: calc(4.166665%*21);
}

.line-22 {
    left: calc(4.166665%*22);
}

.line-23 {
    left: calc(4.166665%*23);
}

h1,
h2,
h3 {
    font-family: Mechsuit;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

@keyframes crtoutput {
    0% {
        opacity: 0.85;
    }
    100% {
        opacity: 1;
    }
}

@keyframes borderstretch {
    0% {
        min-width: 1rem;
        min-height: 1.5rem;
    }
    50% {
        min-width: 1.5rem;
        min-height: 1rem;
    }
    100% {
        min-width: 1rem;
        min-height: 1.5rem;
    }
}

#app {
    z-index: 5;
    position: relative;
    font-family: 'Rajdhani', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: white;
    height: 100vh;
    &:before {
        animation: crtoutput 10ms infinite;
        mix-blend-mode: multiply;
        width: 100vw;
        height: 100vh;
        display: flex;
        position: fixed;
        background-size: auto;
        background-image: radial-gradient( #ffd30033, #ffb10022, black, black);
    }
}

.clocktext {
    text-align: left;
    position: fixed;
    width: 10rem;
    height: 3rem;
    padding-left: 0.5rem;
    left: 0rem;
    top: calc(100vh - 3rem);
    z-index: 5;
    font-weight: bold;
    color: #ffd300;
    &:after {
        content: '';
        width: 10rem;
        height: 2rem;
        position: absolute;
        background-position: left;
        top: -2.5rem;
        left: 0rem;
        background-repeat: no-repeat;
        background-image: url(./assets/caution.svg);
        background-size: 10rem;
    }
    &:before {
        content: '';
        position: absolute;
        background-position: top left;
        bottom: 0.5rem;
        right: 0rem;
        background-image: linear-gradient(135deg, transparent, transparent calc(100% - 0.5rem), #ffd300 calc(100% - 0.5rem), #ffd300 100%);
        clip-path: polygon(100% 0, 100% calc(100% - 0.7rem), calc(100% - 0.7rem) 100%, 0 100%, 0 0);
        border-bottom: 1px solid #ffd300;
        border-right: 1px solid #ffd300;
        animation: borderstretch 1s ease infinite;
    }
}

body {
    /*background-image: url(./assets/city.jpg);*/
    background-size: cover;
    background-color: black;
}

.left-complications {
    display: flex;
    position: fixed;
    z-index: 100;
    width: 10rem;
    height: 0px;
    left: 0px;
    top: 0px;
    z-index: 15;
    height: 13rem;
    border-right: 2px solid #ffd300;
    &:after {
        content: '';
        position: absolute;
        right: -2.8px;
        bottom: 0.5px;
        height: 10rem;
        width: 3px;
        background-color: #ffd300;
        transform-origin: bottom center;
        transform: rotate(-135deg);
        border-radius: 1rem;
    }
    &:before {
        border-radius: 1rem;
        content: '';
        position: absolute;
        left: 2.87rem;
        bottom: -6.9rem;
        height: calc(100vh - 25.5rem);
        width: 2px;
        background-image: linear-gradient(to top, #ffd300, transparent);
        transform-origin: bottom center;
        transform: rotate(-180deg);
    }
    .menu {
        z-index: 10;
        display: flex;
        flex-flow: column;
        top: 5rem;
        position: absolute;
        width: 100%;
        text-align: center;
        transition: all 0.5s ease;
        a {
            transition: all 0.5s ease;
            font-weight: bold;
            width: 100%;
            padding: 1rem;
            line-height: 2rem;
            color: #ffd300;
            &:hover {
                text-shadow: 0px 0px 5px;
                &:after {
                    content: '';
                    position: absolute;
                    left: 1rem;
                    height: 1.5rem;
                    width: 8px;
                    margin-top: 0.25rem;
                    border-left: 1px dotted #ffd300aa;
                    border-top: 1px solid #ffd300;
                    border-bottom: 1px solid #ffd300;
                }
                &:before {
                    content: '';
                    position: absolute;
                    right: 1rem;
                    height: 1.5rem;
                    width: 8px;
                    margin-top: 0.25rem;
                    border-right: 1px dotted #ffd300aa;
                    border-top: 1px solid #ffd300;
                    border-bottom: 1px solid #ffd300;
                }
            }
            &:after {
                transition: all 0.5s ease;
                content: '';
                position: absolute;
                left: 1rem;
                height: 2rem;
                width: 2px;
                border-left: 1px dotted #ffd300aa;
                border-top: 1px solid #ffd300;
                border-bottom: 1px solid #ffd300;
            }
            &:before {
                transition: all 0.5s ease;
                content: '';
                position: absolute;
                right: 1rem;
                height: 2rem;
                width: 2px;
                border-right: 1px dotted #ffd300aa;
                border-top: 1px solid #ffd300;
                border-bottom: 1px solid #ffd300;
            }
        }
    }
}

.headi {
    position: absolute;
    top: 2rem;
    right: 2rem;
    width: auto;
    height: 4rem;
}

.debug {
    position: absolute;
    bottom: 0px;
    width: 13rem;
    bottom: 0.5rem;
    left: -3.5rem;
}

.a-dialog {
    height: auto !important;
}

.a-dialog-button {
    height: 48px !important;
}

.a-orientation-modal {
    font-family: 'Rajdhani', sans-serif !important;
}

.a-orientation-modal>button {
    background: unset !important;
    width: 100vw !important;
    height: 100vh !important;
}

.a-loader-title {
    color: #FFD400;
    opacity: 0;
}

.lense {
    width: 48px;
    height: 48px;
    margin: 8px;
}

.vr {
    width: 64px;
    height: 64px;
}

.a-dialog {
    border-radius: 1rem !important;
    font-family: 'Rajdhani', sans-serif !important;
}

.a-dialog-allow-button {
    font-family: 'Rajdhani', sans-serif !important;
    font-weight: bold !important;
    color: black !important;
    background-color: #FFD400 !important;
    border-radius: 0.5rem !important;
}

.a-dialog-deny-button {
    font-family: 'Rajdhani', sans-serif !important;
    font-weight: bold !important;
    color: black !important;
    background-color: white !important;
    border-radius: 0.5rem !important;
    ;
}

.a-dialog-ok-button {
    background-color: green !important;
    border-radius: 0.5rem !important;
    ;
}

.exit {
    z-index: 100;
    color: white;
    line-height: 0.8rem;
    cursor: pointer;
    left: 1rem;
    width: 8rem;
    height: 3rem;
    top: 1rem;
    position: absolute;
    transition: all 0.5s ease;
    &:hover {
        div {
            &::before {
                transition: all 0.5s ease;
                content: '';
                bottom: 0px;
                right: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-bottom: 1px solid #ffd300;
                border-right: 1px solid #ffd300;
            }
            &::after {
                transition: all 0.5s ease;
                content: '';
                top: 0px;
                left: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-top: 1px solid #ffd300;
                border-left: 1px solid #ffd300;
            }
            h3 {
                margin-top: 0rem;
                background: #ffd300;
                color: black;
                padding: 1rem;
                padding-bottom: 1.2rem;
                &::before {
                    content: '';
                    top: 0px;
                    left: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-top: 1px solid #ffd300;
                    border-bottom: 1px solid #ffd300;
                    border-left: 1px solid #ffd300;
                }
                &::after {
                    content: '';
                    bottom: 0px;
                    right: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-top: 1px solid #ffd300;
                    border-bottom: 1px solid #ffd300;
                    border-right: 1px solid #ffd300;
                }
            }
        }
    }
    div {
        &::before {
            transition: all 0.5s ease;
            content: '';
            bottom: 0px;
            right: 0px;
            min-height: 0.5rem;
            min-width: 0.5rem;
            z-index: 20;
            display: flex;
            position: absolute;
            border-bottom: 1px solid white;
            border-right: 1px solid white;
        }
        &::after {
            transition: all 0.5s ease;
            content: '';
            top: 0px;
            left: 0px;
            min-height: 0.5rem;
            min-width: 0.5rem;
            z-index: 20;
            display: flex;
            position: absolute;
            border-top: 1px solid white;
            border-left: 1px solid white;
        }
        h3 {
            transition: all 0.5s ease;
            padding-bottom: 1.2rem;
            padding: 0rem;
            margin-top: 1rem;
            &::before {
                transition: all 0.5s ease;
                content: '';
                bottom: 0px;
                left: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-bottom: 1px solid white;
                border-left: 1px solid white;
            }
            &::after {
                transition: all 0.5s ease;
                content: '';
                top: 0px;
                right: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-top: 1px solid white;
                border-right: 1px solid white;
            }
        }
    }
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: white;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

@media (max-width: 1024px) {
    .clocktext {
        text-align: right;
        position: absolute;
        top: 1rem;
        padding: 0.5rem;
        line-height: 1rem;
        font-size: 1rem;
        height: 3rem;
        right: 1rem;
        left: unset;
        background-image: linear-gradient(-135deg, black, black calc(100% - 0.5rem), #ffd300 calc(100% - 0.5rem), #ffd300 100%);
        color: #ffd300;
        &:after {
            content: '';
            position: absolute;
            left: -0.5rem;
            top: -0.5rem;
            height: 4rem;
            width: 2px;
            border-top: 1px solid black;
            border-bottom: 1px solid black;
            animation: unset;
        }
        &:before {
            content: '';
            display: none;
        }
    }
    .tg {
        clip-path: polygon(100% 0, 100% calc(100% - 0.7rem), calc(100% - 0.7rem) 100%, 0 100%, 0 0);
        background: #ffd300;
        padding: 1rem;
        width: 5rem;
        position: absolute;
        margin: 0rem;
        left: 10rem;
    }
    .left-complications {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        width: 100vw;
        height: 5rem;
        background-color: #ffd300;
        &:after {
            content: '';
            position: absolute;
            right: -3px;
            bottom: 1px;
            height: 5rem;
            width: 3px;
            background-color: unset;
            background-image: linear-gradient(-135deg, transparent, #ffd300);
            transform-origin: bottom center;
            transform: rotate(-135deg);
            border-radius: 1rem;
        }
        &:before {
            content: '';
            position: absolute;
            right: unset;
            left: -1px;
            bottom: 1px;
            height: 5rem;
            width: 3px;
            background-color: unset;
            background-image: linear-gradient(135deg, transparent, #ffd300);
            transform-origin: bottom center;
            transform: rotate(135deg);
            border-radius: 1rem;
        }
        .menu {
            z-index: 10;
            display: flex;
            top: 1rem;
            position: absolute;
            width: auto;
            left: 5rem;
            text-align: center;
            a {
                line-height: 1.25rem;
                text-align: center;
                margin-bottom: 0.5rem;
                height: 1.25rem;
                text-align: center !important;
                width: 5rem;
                display: flex;
                flex-flow: row;
                background: black;
                color: #ffd300;
                padding: unset;
                justify-content: center;
                &:hover {
                    text-shadow: unset !important;
                    &:after {
                        content: '';
                        display: none;
                    }
                    &:before {
                        content: '';
                        display: none;
                    }
                }
                &:after {
                    content: '';
                    display: none;
                }
                &:before {
                    content: '';
                    display: none;
                }
            }
        }
    }
    .headi {
        position: absolute;
        top: 1rem;
        right: unset;
        left: 7rem;
        width: auto;
        height: 4rem;
    }
    .exit {
        z-index: 100;
        color: black;
        line-height: 0.8rem;
        cursor: pointer;
        left: 1rem;
        width: 3rem;
        height: 3rem;
        top: 1rem;
        position: absolute;
        transition: all 0.5s ease;
        b {
            display: none;
        }
        &:hover {
            div {
                max-width: 48px !important;
                &::before {
                    transition: all 0.5s ease;
                    content: '';
                    bottom: 0px;
                    right: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-bottom: 1px solid #ffd300;
                    border-right: 1px solid #ffd300;
                }
                &::after {
                    transition: all 0.5s ease;
                    content: '';
                    top: 0px;
                    left: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-top: 1px solid #ffd300;
                    border-left: 1px solid #ffd300;
                }
                h3 {
                    max-width: 48px;
                    transition: all 0.5s ease;
                    margin-top: 0rem;
                    background: #ffd300;
                    color: black;
                    padding: 1rem;
                    margin-left: -0.25rem;
                    padding-bottom: 1.2rem;
                    &::before {
                        content: '';
                        top: 0px;
                        left: 0px;
                        min-height: 0.5rem;
                        min-width: 0.5rem;
                        z-index: 20;
                        display: flex;
                        position: absolute;
                        border-top: 1px solid #ffd300;
                        border-bottom: 1px solid #ffd300;
                        border-left: 1px solid #ffd300;
                    }
                    &::after {
                        content: '';
                        bottom: 0px;
                        right: 0px;
                        min-height: 0.5rem;
                        min-width: 0.5rem;
                        z-index: 20;
                        display: flex;
                        position: absolute;
                        border-top: 1px solid #ffd300;
                        border-bottom: 1px solid #ffd300;
                        border-right: 1px solid #ffd300;
                    }
                }
            }
        }
        div {
            max-width: 48px !important;
            &::before {
                transition: all 0.5s ease;
                content: '';
                bottom: 0px;
                right: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-bottom: 1px solid black;
                border-right: 1px solid black;
            }
            &::after {
                transition: all 0.5s ease;
                content: '';
                top: 0px;
                left: 0px;
                min-height: 0.5rem;
                min-width: 0.5rem;
                z-index: 20;
                display: flex;
                position: absolute;
                border-top: 1px solid black;
                border-left: 1px solid black;
            }
            h3 {
                max-width: 48px;
                transition: all 0.5s ease;
                margin-top: 0rem;
                background: #ffd300;
                color: black;
                padding: 1rem;
                margin-left: -0.25rem;
                padding-bottom: 1.2rem;
                &::before {
                    transition: all 0.5s ease;
                    content: '';
                    bottom: 0px;
                    left: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-bottom: 1px solid black;
                    border-left: 1px solid black;
                }
                &::after {
                    transition: all 0.5s ease;
                    content: '';
                    top: 0px;
                    right: 0px;
                    min-height: 0.5rem;
                    min-width: 0.5rem;
                    z-index: 20;
                    display: flex;
                    position: absolute;
                    border-top: 1px solid black;
                    border-right: 1px solid black;
                }
            }
        }
    }
}
</style>
